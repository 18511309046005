<template>

    <el-container>
      <el-header height="148px" >
        <!--路由历史区域-->
        <div class="route-log">
          <app-header-top></app-header-top>
          <app-header-bottom></app-header-bottom>
        </div>
        <!---头部区域-->
      </el-header>
      <el-main>
        <!--内容区域-->
        <router-view></router-view>
      </el-main>

      <el-footer height="260px" >
        <app-bottom></app-bottom>
      </el-footer>
    </el-container>

</template>

<script>
export default {
  components: {
    "app-bottom": () => import("./modules/app-bottom.vue"),
    "app-header-top": () => import("./modules/app-header-top.vue"),
    "app-header-bottom": () => import("./modules/app-header-bottom.vue")
  },
  data() {
    return {
      toUrl: {
        name: "home"
      }
    };
  }
};
</script>

<style lang="less">
.el-container {
  width: 100%;
}
.el-aside {
  padding: 0;
  background: #303133;
}
.el-header {
  padding: 0px;
}
.el-main {
  padding: 0;
  min-height: 529px;
  background: #f5f5f5;
}
.el-footer{
  padding: 0;
}
.shouyeTb{
  width: 17px;
  height: 17px;
  background: url("./icon/shouyeTb.png") ;
  background-size: 100%;
  }
.gongchengTb{
  width: 17px;
  height: 17px;
  background: url("./icon/gongchengTb.png") ;
  background-size: 100%;
}
.tajiTb{
  width: 17px;
  height: 17px;
  background: url("./icon/tajiTb.png") ;
  background-size: 100%;
}
.yangchenTb{
  width: 17px;
  height: 17px;
  background: url("./icon/yangchenTb.png") ;
  background-size: 100%;
}
.monitorVideo{
  width: 17px;
  height: 17px;
  background: url("./icon/monitorVideo.png") ;
  background-size: 100%;
}
.attendanceTb{
  width: 17px;
  height: 17px;
  background: url("./icon/attendance.png") ;
  background-size: 100%;
}
</style>
